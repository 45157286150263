.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* align-content: center; */
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

