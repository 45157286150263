

/* .element-navbar-login {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    gap: 75px;
} */

.espacio-elementos {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    gap: 75px;
}

/* .logoPlaizt {
    height: 180px;
    width: 140px; 
    top: 40px;
} */

/* Formulario */


@media (max-width: 1280px) {
    .espacio-elementos {
        gap: 1080px;
    }
}

@media (max-width: 1258px) {
    .espacio-elementos {
        gap: 1060px;
    }
}

@media (max-width: 1238px) {
    .espacio-elementos {
        gap: 1040px;
    }
} 

@media (max-width: 1198px) {
    .espacio-elementos {
        gap: 1020px;
    }
} 


@media (max-width: 1180px) {
    .espacio-elementos {
        gap: 1000px;
    }
}


@media (max-width: 1160px) {
    .espacio-elementos {
        gap: 990px;
    }
}

@media (max-width: 1129px) {
    .espacio-elementos {
        gap: 960px;
    }
}

@media (max-width: 1130px) {
    .espacio-elementos {
        gap: 940px;
    }
}


@media (max-width: 1110px) {
    .espacio-elementos {
        gap: 900px;
    }
}  

@media (max-width: 1090px) {
    .espacio-elementos {
        gap: 880px;
    }
}

@media (max-width: 1070px) {
    .espacio-elementos {
        gap: 860px;
    }
}

@media (max-width: 1050px) {
    .espacio-elementos {
        gap: 840px;
    }
}

@media (max-width: 1030px) {
    .espacio-elementos {
        gap: 820px;
    }
}


@media (max-width: 1020px) {
    .espacio-elementos {
        gap: 800px;
    }
}

@media (max-width: 1000px) {
    .espacio-elementos {
        gap: 780px;
    }
}

@media (max-width: 980px) {
    .espacio-elementos {
        gap: 760px;
    } 
}

@media (max-width: 960px) {
    .espacio-elementos {
        gap: 740px;
    }
}

@media (max-width: 940px) {
    .espacio-elementos {
        gap: 720px;
    }
}

@media (max-width: 920px) {
    .espacio-elementos {
        gap: 700px;
    }
}

@media (max-width: 900px) {
    .espacio-elementos {
        gap: 620px;
    }
}

@media (max-width: 880px) {
    .espacio-elementos {
        gap: 600px;
    }
}

@media (max-width: 830px) {
    .espacio-elementos {
        gap: 580px;
    }
}

@media (max-width: 799px) {
    .espacio-elementos {
        gap: 560px;
    }
}

@media (max-width: 775px) {
    .espacio-elementos {
        gap: 530px;
    }
}

@media (max-width: 740px) {
    .espacio-elementos {
        gap: 503px;
    }
}


@media (max-width: 720px) {
    .espacio-elementos {
        gap: 480px;
    }
}

@media (max-width: 702px) {
    .espacio-elementos {
        gap: 460px;
    }
}

@media (max-width: 690px) {
    .espacio-elementos {
        gap: 448px;
    }
}


@media (max-width: 645px) {
    .espacio-elementos {
        gap: 420px;
    }
}

@media (max-width: 624px) {
    .espacio-elementos {
        gap: 390px;
    }
}

@media (max-width: 619px) {
    .espacio-elementos {
        gap: 370px;
    }
}


@media (max-width: 590px) {
    .espacio-elementos {
        gap: 340px;
    }
}

@media (max-width: 560px) {
    .espacio-elementos {
        gap: 320px;
    }
}

@media (max-width: 540px) {
    .espacio-elementos {
        gap: 300px;
    }
}

@media (max-width: 520px) {
    .espacio-elementos {
        gap: 280px;
    }
}

@media (max-width: 495px) {
    .espacio-elementos {
        gap: 255px;
    }
}

@media (max-width: 468px) {
    .espacio-elementos {
        gap: 225px;
    }
}

@media (max-width: 438px) {
    .espacio-elementos {
        gap: 190px;
    }
}

@media (max-width: 410px) {
    .espacio-elementos {
        gap: 190px;
    }
}


@media (max-width: 395px) {
    .espacio-elementos {
        gap: 160px;
    }
}

@media (max-width: 372px) {
    .espacio-elementos {
        gap: 130px;
    }
}


@media (max-width: 360px) {
    .espacio-elementos {
        gap: 130px;
    }
}

@media (max-width: 343px) {
    .espacio-elementos {
        gap: 80px;
    }
}

@media (max-width: 310px) {
    .espacio-elementos {
        gap: 70px;
    }
}

@media (max-width: 284px) {
    .espacio-elementos {
        gap: 50px;
    }
}

@media (max-width: 260px) {
    .espacio-elementos {
        gap: 40px;
    }
}

@media (max-width: 243px) {
    .espacio-elementos {
        gap: 40px;
    }
}

@media (max-width: 220px) {
    .espacio-elementos {
        gap: 10px;
    }
}

@media (max-width: 200px) {
    .espacio-elementos {
        gap: 0px;
    }
}