/* header-preguntas */
/* header-rango */
.header-preguntas {
    margin-top: 160px;
    padding: 1rem;
    align-self: start;
    margin-left: 25px;
}


.question-container{
    padding: 1rem; /* 1rem son 16 px*/
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
} 

.options { /* Permite dejar el tamaño de las opciones en el tamaño indicado*/
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 272px;
    height: 36px;
}

.css-1h06qz8-control { /* Permite dejar la caja en el tamaño indicado*/
    width: 272px;
    height: 36px;
}

.css-1un73jv-control { /* Permite dejar la seleccion en el tamaño indicado */
    width: 272px;
    height: 36px;
}

@media (max-width:350px) {
    .options {
        width: 207px;
    }

    .css-1h06qz8-control{
        width: 207px;
    }
    
    .css-1un73jv-control {
        width: 207px;
    }
}