/* Navbar */


.nav-preguntas {    
    margin: 0;
    margin-bottom: 45px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
} 

.element-navbar-preguntas {

    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    /* align-items: center; */
    
    
    /* flex-direction: row;
    align-items: center; /* centra las imagenes en la caja 
     */
} 

.logoPlaizt {
    position: absolute;
    height: 175px;
    width: 110px;
    /* right: 40px; */
    right: 15px;
    /* top: 0px; */
    z-index: 1
}

.decoracion {
    position: absolute;
    /* height: 225px; */
    width: 207px;
    right: 0px;
    top: 0px;
}

.element-navbar-logos {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    position: relative;
    z-index: 2;
}

@media (max-width: 400) {
    .logo {
        position: absolute;
        height: 70px;
        width: 30px;
        top: 0px;
    }

    .decoracion {
        position: absolute;
        background-size: 115px;
        width: 97px;
        right: 0px;
        top: 0px;
    }
    
}