.seccionButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
}

.BotonSi {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 8px;
    background: #dcdada;
    cursor: pointer;
    border: none;
}

.BotonNo {
    display: flex;
    flex-direction: row;

    background: #dcdada;
    border-radius: 8px ;
    cursor: pointer;

    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;

    border: none;
}

.BotonNo:hover {
    background: #FF007E ;
}

.BotonNo.isActive {
    background: #FF007E;
}

.BotonSi:hover {
    background: #FF007E;
}

.BotonSi.isActive {
    background: #FF007E;
}