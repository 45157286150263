
.header-login {
    margin: 50px;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
}

h4 {
    font-weight: normal;
}

/* Formulario */

.container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
} 

.input {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 8px;
    width: 272px;
    height: 36px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    border: 1px solid #e7e6e6; 
}


@media (max-width:350px) {
    .input  {
        margin: 8px;
        width: 212px;
        height: 36px;
        padding-left: 9px;
        border-radius: 12px;
        gap: 12px;
    }

}

/* Quita las fechas del input de telefono. */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}