input[type="radio"] {
    display: none;
    margin-top: 50px;
}

.star {
    cursor: pointer;
    transition: color 200ms;
}

